<template>
  <div>
    <Pane borderRight>
      <PaneHeader id="conversationsHeader">
        <template #title>Conversations</template>
      </PaneHeader>

      <!-- body -->
      <!-- filters -->
      <div class="p-3" id="conversationsFilters">
        <div class="d-inline-block">
          <h6 class="header-pretitle">Location</h6>
          <GroupingFilter
            :nonGroupMembers="selectActiveLocations"
            nonGroupMemberLabel="Locations"
            memberType="location"
            @update="filterByLocation"
            :init="{}"
            :locationsOnly="true"
          />
        </div>

        <div class="px-3 mt-2 search-border">
          <StringFilter
            :isSearching="queryPending"
            @updateSearch="filterByString"
            @onTextChanged="onSearchTextChanged"
          />
        </div>

        <div class="btn-group btn-group-sm mt-2 d-flex w-100" role="group">
          <button
            class="btn btn-light w-100"
            :class="{ active: statusFilter === 'All' }"
            @click="filterByStatus('All')"
          >
            All
          </button>
          <button
            class="btn btn-light w-100"
            :class="{ active: statusFilter === 'Unresolved' }"
            @click="filterByStatus('Unresolved')"
          >
            Unresolved
          </button>
          <button
            class="btn btn-light w-100"
            :class="{ active: statusFilter === 'Resolved' }"
            @click="filterByStatus('Resolved')"
          >
            Resolved
          </button>
        </div>
      </div>

      <!-- conversation list -->
      <div
        id="conversationList"
        class="px-1"
        :style="{
          height: conversationListHeight + 'px',
          overflow: 'scroll',
          'overscroll-behavior-y': 'contain',
        }"
      >
        <div v-if="conversationListHeight && !queryPending">
          <div v-for="conversation of conversations" :key="conversation.uuid">
            <ConversationCard :conversation="conversation" @select="selectConversation" />
            <hr class="my-1" />
          </div>
        </div>
        <div v-else>
          <div v-for="index in 7" :key="index" class="px-3">
            <b-skeleton height="150px"></b-skeleton>
          </div>
        </div>
        <div v-if="fetchMorePending" class="d-flex justify-content-center align-items-center my-3">
          <b-spinner small variant="primary" />
        </div>
        <div
          v-if="selectListExhausted && conversations.length"
          class="d-flex justify-content-center align-items-center my-3"
        >
          No more conversations
        </div>
        <div
          v-if="selectListExhausted && !conversations.length"
          class="d-flex justify-content-center align-items-center h-100"
        >
          <div class="text-center">
            <i class="fas fa-inbox" style="font-size: 32px"></i>
            <div>No conversations</div>
          </div>
        </div>
      </div>
    </Pane>

    <TimelineDrawer
      v-if="selectIsMobileView"
      ref="timelineDrawer"
      @toggleDetails="$emit('toggleDetails')"
    />
  </div>
</template>

<script>
import Pane from '../Components/Pane'
import TimelineDrawer from '../Components/TimelineDrawer'
import { trackEvent } from '@/lib/analytics'
import PaneHeader from '../Components/PaneHeader'
import ConversationCard from '../Components/ConversationCard'
import CustomerStringFilter from '@/components/Modules/Customer/CustomerFilters/CustomerStringFilter'
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import { createNamespacedHelpers } from 'vuex'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const LocationModule = createNamespacedHelpers('location')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'ConversationList',
  components: {
    Pane,
    PaneHeader,
    ConversationCard,
    StringFilter: CustomerStringFilter,
    GroupingFilter,
    TimelineDrawer,
  },
  props: {
    queryPending: {
      type: Boolean,
      default: false,
    },
    fetchMorePending: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    statusFilter: 'Unresolved',
    conversationListHeight: 0,
    filteredLocations: [],
    searchString: '',
    resizeObserver: null,
  }),
  async mounted() {
    await this.setConversationListHeight()
    $('#conversationList')[0].addEventListener('scroll', this.evaluateScroll)
    window.addEventListener('resize', this.setConversationListHeight)
    this.resizeObserver = new ResizeObserver(this.setConversationListHeight)
    // the observer on 'body' is for banners
    this.resizeObserver.observe(document.querySelector('body'))
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setConversationListHeight)
    this.resizeObserver.disconnect()
    $('#conversationList')?.[0]?.removeEventListener('scroll', this.evaluateScroll)
  },
  computed: {
    ...ConversationModuleV2.mapGetters([
      'selectConversations',
      'selectSearchedConversations',
      'selectListExhausted',
      'selectIsMobileView',
    ]),
    ...LocationModule.mapGetters(['selectActiveLocations', 'selectDisabledLocations']),
    ...CompanyModule.mapGetters(['activeCompany']),
    ...UserModule.mapGetters(['onlineUser']),
    conversations() {
      return this.searchString ? this.selectSearchedConversations : this.selectConversations
    },
  },
  methods: {
    ...ConversationModuleV2.mapActions([
      'updateFilters',
      'setActiveConversation',
      'updateIsDeeplink',
      'setSearchString',
    ]),
    async setConversationListHeight() {
      this.conversationListHeight =
        document.querySelector('body').clientHeight -
        $('#conversationsHeader')[0].offsetHeight -
        $('#conversationsFilters')[0].offsetHeight -
        (this.selectIsMobileView ? $('#nav-main')[0].offsetHeight : 0)
    },
    evaluateScroll(event) {
      var element = event.target
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 300) {
        if (!this.fetchMorePending && !this.selectListExhausted) {
          console.log('FETCH MORE')
          this.$emit('fetchMore')
        }
      }
    },
    filterByLocation(val) {
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : []
      this.formatAndApplyFilters()
    },
    filterByString(text) {
      trackEvent(this.$intercom, 'Winback_Search', {
        companyId: this.activeCompany._id,
        query: text,
        userId: this.onlineUser._id,
      })
      this.searchString = text
      this.formatAndApplyFilters()
    },
    filterByStatus(status) {
      if (this.statusFilter !== status) {
        this.statusFilter = status
        this.formatAndApplyFilters()
      }
    },
    formatAndApplyFilters() {
      this.updateFilters({
        locationIds: this.filteredLocations,
        search: this.searchString,
        ...(this.statusFilter === 'All'
          ? {}
          : { resolved: this.statusFilter === 'Resolved' ? true : false }),
      })

      this.$emit('filtersUpdated')
    },
    selectConversation(selectedConversation) {
      const { conversationId } = this.$route.params
      if (conversationId && conversationId !== selectedConversation._id) {
        this.updateIsDeeplink(false)
      }
      this.setActiveConversation(selectedConversation)
      if (this.selectIsMobileView) {
        this.$refs.timelineDrawer.open()
      }
      trackEvent(this.$intercom, 'Message_Detail')
    },
    onSearchTextChanged(value) {
      if (value) {
        this.setSearchString(value)
      } else {
        this.setSearchString(undefined)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.search-border {
  background: white;
  border: 1px solid #d2ddec !important;
  border-radius: 6px !important;
}
.btn {
  box-shadow: none;
}
.btn-light {
  background-color: #edf2f9 !important;
  color: black;
}
.btn-light:active,
.btn-light.active,
.btn-light:hover,
.btn-light:visited {
  background-color: white !important;
  color: black;
  border-color: #d2ddec !important;
}
.btn-group > .btn.active {
  z-index: 0;
}
</style>
